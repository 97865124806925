@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Playwrite+CU:wght@100..400&display=swap');
 
:root {
  --foreground-rgb: rgb(211, 216, 232);
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
  }
}

body {
  color: rgb(var(--foreground-rgb));
  background-color: #0d1224;
}

#sticky-card-1 {
  --index: 1;
}

#sticky-card-2 {
  --index: 2;
}

#sticky-card-3 {
  --index: 3;
}

#sticky-card-4 {
  --index: 4;
}

#sticky-card-5 {
  --index: 5;
}

#sticky-card-6 {
  --index: 6;
}
#sticky-card-7 {
  --index: 7;
}
#sticky-card-8 {
  --index: 8;
}
#sticky-card-9 {
  --index: 9;
}
#sticky-card-10 {
  --index: 10;
}
.sticky-card {
  top: calc(var(--index) * 4rem);
}

.hoverBtn:hover{
  display:flex;
  gap:12px !important;
}


.glow-container {
  --spread: 60;
}

.glow-card {
  --active: 0.15;
  --start: 0;
  border: 1px solid #2a2e5a
}

.glow-card:hover,
.glow-card:focus-visible {
  z-index: 2;
}

.glows {
  @apply pointer-events-none absolute inset-0;
  filter: blur(calc(var(--blur) * 1px));
}

.glows::after,
.glows::before {
  --alpha: 0;
  content: "";
  background: conic-gradient(from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72.0000010728836deg,
      #f626af 144.0000021457672deg,
      #0025ba 216.00000858306885deg,
      #f626af 288.0000042915344deg,
      #fafafa 1turn);
  background-attachment: fixed;
  @apply absolute inset-0 border-8 border-transparent rounded-[12px];
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc((var(--start) - (var(--spread) * 0.5)) * 1deg),
      #000 0deg,
      #fff,
      #0000 calc(var(--spread) * 1deg));
  mask-composite: intersect;
  mask-clip: padding-box, border-box;
  opacity: var(--active);
  transition: opacity 1s;
}

.glow-card::before {
  @apply absolute inset-0 border-2 border-transparent;
  content: "";
  @apply rounded-[12px] pointer-events-none;
  background: #84738c;
  background-attachment: fixed;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 1.5)) * 1deg),
      #ffffff26 0deg,
      white,
      #ffffff26 calc(var(--spread) * 2.5deg));
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
  opacity: var(--active);
  transition: opacity 1s;
}

.glow-card::after {
  --bg-size: 100%;
  content: "";
  @apply pointer-events-none absolute;
  background: conic-gradient(from 180deg at 50% 70%,
      #f626af 0deg,
      #8228ec 72.0000010728836deg,
      #f626af 144.0000021457672deg,
      #0025ba 216.00000858306885deg,
      #f626af 288.0000042915344deg,
      #fafafa 1turn);
  background-attachment: fixed;
  @apply rounded-[12px];
  opacity: var(--active, 0);
  transition: opacity 1s;
  --alpha: 0;
  @apply inset-0 border-2 border-transparent;
  mask: linear-gradient(#0000, #0000),
    conic-gradient(from calc(((var(--start) + (var(--spread) * 0.25)) - (var(--spread) * 0.5)) * 1deg),
      #0000 0deg,
      #fff,
      #0000 calc(var(--spread) * 0.5deg));
  filter: brightness(1.5);
  mask-clip: padding-box, border-box;
  mask-composite: intersect;
}
